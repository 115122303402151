<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 pa-0 ma-0 tgl-page-header">
        <tgl-breadcrumbs :items="breadcrumbs" />
        <device-actions v-if="$store.getters.getPermission('devices_delete')" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 pa-0 ma-0">
        <v-tabs>
          <v-tab
            :to="{
              name: 'deviceDetails',
              params: { device: $route.params.device },
            }"
            data-v-step="6"
            >{{ $t("device.deviceTab.detail") }}</v-tab
          >
          <v-tab
            :to="{
              name: 'deviceConfiguration',
              params: { device: $route.params.device },
            }"
            v-if="$store.getters.getPermission('devices_edit')"
            data-v-step="7"
            >{{ $t("device.deviceTab.configuration") }}</v-tab
          >
          <v-tab
            :to="{
              name: 'deviceSlots',
              params: { device: $route.params.device },
            }"
            data-v-step="8"
            >{{ $t("device.deviceTab.slots") }}</v-tab
          >
          <v-tab
            :to="{
              name: 'deviceMonitoring',
              params: { device: $route.params.device },
            }"
            data-v-step="5"
            >{{ $t("device.deviceTab.monitoring") }}</v-tab
          >
          <v-tab
            :to="{
              name: 'deviceEvents',
              params: { device: $route.params.device },
            }"
            >{{ $t("device.deviceTab.events") }}</v-tab
          >
          <v-tab
            :to="{
              name: 'deviceEventListeners',
              params: { device: $route.params.device },
            }"
            >{{ $t("device.deviceTab.eventListener") }}</v-tab
          >
          <v-tab
            :to="{
              name: 'deviceStreaming',
              params: { device: $route.params.device },
            }"
            >{{ $t("device.deviceTab.streaming") }}</v-tab
          >
        </v-tabs>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TglBreadcrumbs from "../../components/UI/TglBreadcrumbs";
import DeviceActions from "../../components/Devices/DeviceActions";

export default {
  components: {
    TglBreadcrumbs,
    DeviceActions,
  },
  data() {
    return {
      device: null,
    };
  },
  methods: {
    getDevice() {
      this.$store
        .dispatch("device/getDevice", this.$route.params.device)
        .then((data) => {
          this.device = data;
        });
    },
  },
  computed: {
    breadcrumbs() {
      return [
        {
          label: "Dispositivos",
          disabled: false,
          to: { name: "devices" },
        },
        {
          label: this.device ? this.device.alias : this.$route.params.device,
          disabled: true,
          to: { name: "devices" },
        },
        {
          label: this.$t(this.$route.meta.title.toLowerCase()),
          disabled: true,
          to: { name: "devices" },
        },
      ];
    },
  },
  mounted() {
    this.getDevice();
  },
};
</script>