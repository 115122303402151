var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pa-0 ma-0 tgl-page-header"},[_c('tgl-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),(_vm.$store.getters.getPermission('devices_delete'))?_c('device-actions'):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pa-0 ma-0"},[_c('v-tabs',[_c('v-tab',{attrs:{"to":{
            name: 'deviceDetails',
            params: { device: _vm.$route.params.device },
          },"data-v-step":"6"}},[_vm._v(_vm._s(_vm.$t("device.deviceTab.detail")))]),(_vm.$store.getters.getPermission('devices_edit'))?_c('v-tab',{attrs:{"to":{
            name: 'deviceConfiguration',
            params: { device: _vm.$route.params.device },
          },"data-v-step":"7"}},[_vm._v(_vm._s(_vm.$t("device.deviceTab.configuration")))]):_vm._e(),_c('v-tab',{attrs:{"to":{
            name: 'deviceSlots',
            params: { device: _vm.$route.params.device },
          },"data-v-step":"8"}},[_vm._v(_vm._s(_vm.$t("device.deviceTab.slots")))]),_c('v-tab',{attrs:{"to":{
            name: 'deviceMonitoring',
            params: { device: _vm.$route.params.device },
          },"data-v-step":"5"}},[_vm._v(_vm._s(_vm.$t("device.deviceTab.monitoring")))]),_c('v-tab',{attrs:{"to":{
            name: 'deviceEvents',
            params: { device: _vm.$route.params.device },
          }}},[_vm._v(_vm._s(_vm.$t("device.deviceTab.events")))]),_c('v-tab',{attrs:{"to":{
            name: 'deviceEventListeners',
            params: { device: _vm.$route.params.device },
          }}},[_vm._v(_vm._s(_vm.$t("device.deviceTab.eventListener")))]),_c('v-tab',{attrs:{"to":{
            name: 'deviceStreaming',
            params: { device: _vm.$route.params.device },
          }}},[_vm._v(_vm._s(_vm.$t("device.deviceTab.streaming")))])],1),_c('router-view')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }