<template>
  <div class="entity-actions">
    <router-link
      :to="{ name: 'createTemplate', params: { device: $route.params.device } }"
      ><v-btn color="primary" class="mr-2" tile>Crear template</v-btn>
    </router-link>
    <tgl-delete-button
      dispatch="device/deleteDevice"
      entity="device"
      :redirect="{ name: 'devices' }"
    />
  </div>
</template>

<script>
import TglDeleteButton from "../UI/TglDeleteButton";
export default {
  components: {
    TglDeleteButton,
  },
};
</script>
